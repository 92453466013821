import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { push } from 'connected-react-router'
import { Formik, FormikConfig } from 'formik'
import { useAppDispatch, useAppSelector, useCurrentRecruiter } from '@hooks/store'
import { useCurrentCompany } from '@hooks/useCompanies'
import { jobDraftUpdatedSuccess, jobSaveSuccess, updateJobScreeningQuestions } from '../../../actions/creators'
import { LOCALE_VARIANTS } from '../../../constants'
import { localeHandler, paths } from '../../../routes/paths'
import { createErrorParser } from '../../JobForm/jobFormUtils'
import { validateForm } from '../form/utils/validateForm'
import ScreeningQuestionsFormContent from './ScreeningQuestionsFormContent'
import { mapToUpdateJobScreeningQuestionsActionPayload, prepareScreeningQuestions } from './utils'
import type { Job, ScreeningQuestion, ScreeningQuestionWithCustomKey } from '../../../types'

export type ScreeningQuestionsFormProps = {
  isSelfServicePath: boolean
  job: Job
  screeningQuestions: ScreeningQuestion[]
}

export type ScreeningQuestionsFormData = {
  localeVariant: keyof typeof LOCALE_VARIANTS
  screeningQuestions: ScreeningQuestionWithCustomKey[]
  submissionError?: string | { messsages: string[] } | null
  shouldRedirectToDashboard?: boolean
  shouldRedirectToAdQualityPage?: boolean
}

const ScreeningQuestionsForm = ({ screeningQuestions, isSelfServicePath, job }: ScreeningQuestionsFormProps) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const companyId = useCurrentCompany().id
  const catalogQuestions = useAppSelector((state) => state.jobs.catalogQuestions)
  const recruiter = useCurrentRecruiter()

  function handleSubmitForm({ localeVariant, screeningQuestions }: ScreeningQuestionsFormData) {
    return dispatch(
      updateJobScreeningQuestions(companyId, {
        job_id: job.id,
        locale_variant: localeVariant,
        screening_questions: screeningQuestions.map(mapToUpdateJobScreeningQuestionsActionPayload),
      })
    )
  }

  function goToDashboard() {
    dispatch(jobDraftUpdatedSuccess())
    dispatch(push(localeHandler(paths.companyDashboard())))
  }

  function showSavedNotification(jobStatus) {
    dispatch(jobStatus === 'submitted_for_publishing' ? jobSaveSuccess() : jobDraftUpdatedSuccess())
  }

  function goToAdQualityPage(jobId: string, isSelfServicePath: boolean) {
    const path = isSelfServicePath ? paths.productSelectionSelfService({ jobId }) : paths.jobAdQuality({ jobId })
    dispatch(push(localeHandler(path)))
  }

  const screeningQuestionsMemiosed = useMemo(
    () =>
      prepareScreeningQuestions(screeningQuestions, catalogQuestions, job.locale_variant, job.locale, job.is_ats_job),
    [screeningQuestions, catalogQuestions, job.locale_variant, job.locale, job.is_ats_job]
  )

  function handlePostSubmitRedirect({
    shouldRedirectToDashboard,
    shouldRedirectToAdQualityPage,
  }: ScreeningQuestionsFormData) {
    if (shouldRedirectToDashboard) {
      goToDashboard()
      return
    }
    if (shouldRedirectToAdQualityPage) {
      goToAdQualityPage(job.id, isSelfServicePath)
      return
    }
    showSavedNotification(job.status)
  }

  const formikProps: FormikConfig<ScreeningQuestionsFormData> = {
    enableReinitialize: true,
    initialValues: {
      localeVariant: job.locale_variant,
      screeningQuestions: screeningQuestionsMemiosed,
    },
    validate: (values) => validateForm(values, catalogQuestions, recruiter?.locale),
    async onSubmit(formValues, { setFieldError }) {
      try {
        await handleSubmitForm(formValues)
        handlePostSubmitRedirect(formValues)
      } catch (err) {
        setFieldError('submissionError', createErrorParser(intl)(err as Record<string, any>)?._error)
      }
    },
  }

  return (
    <Formik {...formikProps}>
      <ScreeningQuestionsFormContent job={job} />
    </Formik>
  )
}

export default ScreeningQuestionsForm
