import React, { useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useFormikContext } from 'formik'
import { useFormatMessage } from '@hooks/util'
import { Job } from '../../../../types'
import { InfoIconWithTooltip } from '../../../InfoIconWithTooltip'
import FormalAddressing from '../../FormalAddressing'
import useScreeningQuestionsFormState from '../useScreeningQuestionsFormState'

const SQFormHeadingAndAddressingModeSection = ({
  job,
  hasNoImportedScreeningQuestions,
}: {
  job: Job
  hasNoImportedScreeningQuestions: boolean
}) => {
  const formatMessage = useFormatMessage()
  const { setFieldValue, setFieldTouched } = useFormikContext()
  const { localeVariant } = useScreeningQuestionsFormState(job)

  const handleLocaleVariantToggle = useCallback(
    (e) => {
      setFieldValue('localeVariant', e.target.value, false)
      setFieldTouched('localeVariant', true, false)
    },
    [setFieldTouched, setFieldValue]
  )

  const showFormalAddressingToggle = hasNoImportedScreeningQuestions && job.locale === 'de'

  return (
    <Box mt={5} mb={3}>
      <Typography variant="h4" mb={2}>
        <FormattedMessage id="employer_screening_questions_page_title" />
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'start', mb: '10px' }}>
        <Typography variant="body1">
          <FormattedMessage id="employer_catalog_explainer_text_summary" />
        </Typography>
        <InfoIconWithTooltip tooltip={formatMessage('employer_catalog_explainer_text_details')} />
      </Box>

      {showFormalAddressingToggle && (
        <FormalAddressing
          data-testid="formal-addressing-toggle"
          mt={2}
          localeVariant={localeVariant}
          onChange={handleLocaleVariantToggle}
        />
      )}
    </Box>
  )
}

export default SQFormHeadingAndAddressingModeSection
