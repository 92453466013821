import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import DownloadIcon from '@mui/icons-material/SaveAltOutlined'
import { Button, ListItemText, Menu, MenuItem } from '@mui/material'
import { ExportTypeHeader, SortParams } from '../../../actions/api/services/genericTypes'
import { ExportType, useExportStatistics } from '../../../hooks/useExportStatistics'

interface StatisticsDataDownloadButtonProps<TFilters> {
  variant?: 'outlined' | 'text'
  exportFn: (companyId: string, filters: TFilters, header: ExportTypeHeader) => Promise<Blob>
  filters: TFilters
  fileNamePrefix: string
  querySort?: SortParams['sort']
}

export default function StatisticsDataDownloadButton<TFilters>({
  variant = 'outlined',
  exportFn,
  filters,
  fileNamePrefix,
  querySort,
}: StatisticsDataDownloadButtonProps<TFilters>) {
  const buttonRef = React.useRef(null)
  const [anchorEl, setAnchorEl] = useState(null)

  const { downloadAs, exportTypesMap, isPending } = useExportStatistics<TFilters>({
    exportFn,
    filters,
    fileNamePrefix,
    querySort,
  })

  function handleClick(event) {
    setAnchorEl(event.currentTarget)
  }

  function handleItemClick(selectedExportType: string) {
    downloadAs(selectedExportType as ExportType)
    setAnchorEl(null)
  }

  return (
    <>
      <Button
        startIcon={<DownloadIcon />}
        variant={variant}
        color="primary"
        size="medium"
        aria-controls="export-menu"
        aria-haspopup="true"
        disabled={isPending}
        onClick={handleClick}
      >
        <FormattedMessage id="employer_statistics_download_button" />
      </Button>
      <Menu
        ref={buttonRef}
        id="export-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {Object.keys(exportTypesMap).map((exportType) => (
          <MenuItem key={exportType} onClick={() => handleItemClick(exportType)}>
            <ListItemText>
              <FormattedMessage id={`employer_statistics_download_button_${exportType}`} />
            </ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
