import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import ArrowBackIcon from '@mui/icons-material/ArrowBackOutlined'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useAppDispatch } from '@hooks/store/useAppDispatch'
import { useMarketingContractGroups } from '@hooks/useMarketingContractGroups'
import { useProductTab } from '@hooks/useProductTab'
import useChartJsPluginsRegistration from '@hooks/util/useChartJsPluginsRegistration'
import { useFormatMessage } from '@hooks/util/useFormatMessage'
import { updateDashboardState } from '../../../actions/creators'
import FunnelChart from '../../../components/Statistics/FunnelChart'
import JobsPerformanceStatistics from '../../../components/Statistics/JobsPerformanceStatistics'
import LiveJobsVsCpaChart from '../../../components/Statistics/LiveJobsVsCpaChart'
import Loader from '../../../components/Statistics/Loader'
import Summary from '../../../components/Statistics/Summary'
import WarningPanel from '../../../components/Statistics/WarningPanel'
import { ProductTabs } from '../../../constants'
import { ReachStatsFilters } from '../../../types'
import Filters from './components/Filters'
import NotLive from './components/NotLive'
import ReachBestLeastPerformingJobs from './components/ReachBestLeastPerformingJobs'
import { JobsPerformanceContextProvider } from './contexts/JobsPerformanceContext'
import { defaultState, ReachStatsContext } from './contexts/ReachStatsContext'
import { useCampaignStats, useCampaignStatsHistory } from './hooks/useReachStats'
import { ReachStatsContextType } from './types'

const NotLiveComponent = () => {
  const dispatch = useAppDispatch()
  const { switchTab } = useProductTab()

  function switchToHire() {
    const newState = { productTab: ProductTabs.HIRE, tab: 'feed' }
    dispatch(updateDashboardState(newState, { reset: true }))
    switchTab(newState.productTab)
  }

  return (
    <NotLive
      titleId="reach_stats_campaign_not_live_title"
      contentId="reach_stats_campaign_no_campaigns_content"
      buttonTextId="dashboard_reach_feed_goto_hire_btn"
      onButtonClick={switchToHire}
      buttonProps={{ startIcon: <ArrowBackIcon /> }}
      addTopMargin
      showButton
    />
  )
}

function _ReachStats() {
  const { noCampaigns } = useMarketingContractGroups()
  const { stats, isSuccess: isStatsLoaded, isLoading, dataUpdatedAt } = useCampaignStats()
  const { history } = useCampaignStatsHistory()
  const formatMessage = useFormatMessage()

  if (isLoading) {
    return (
      <Box height="30vh">
        <Loader />
      </Box>
    )
  }

  if (noCampaigns) {
    return <NotLiveComponent />
  }

  if (!isStatsLoaded) {
    return null
  }

  if (!stats.was_ever_live) {
    return <NotLiveComponent />
  }

  const funnelData = {
    views: stats?.views ?? 0,
    application_started_count: stats?.application_started_count ?? 0,
    application_submitted_count: stats?.application_submitted_count ?? 0,
    application_sent_count: stats?.application_sent_count ?? 0,
    gold_application_count: stats?.gold_application_count ?? 0,
  }

  return (
    <>
      <WarningPanel warningFlags={stats?.warning_flags || []} />
      <Box key={dataUpdatedAt} display="flex" flexDirection="column" gap={6}>
        <Summary />
        <FunnelChart
          current={funnelData}
          history={history}
          tooltip={formatMessage('reach_stats_funnel_chart_title_tooltip')}
          title={formatMessage('reach_stats_funnel_chart_title')}
        />
        <ReachBestLeastPerformingJobs />
        <LiveJobsVsCpaChart />
        <JobsPerformanceStatistics />
      </Box>
    </>
  )
}

export default function ReachStats() {
  const [filters, setFilters] = useState<ReachStatsFilters>(defaultState)
  const context: ReachStatsContextType = { filters, setFilters }

  useChartJsPluginsRegistration()

  return (
    <>
      <Box my={3}>
        <Typography variant="subtitle1">
          <FormattedMessage id="statistics_subtitle_reach" />
        </Typography>
      </Box>
      <ReachStatsContext.Provider value={context}>
        <JobsPerformanceContextProvider>
          <Filters />
          <_ReachStats />
        </JobsPerformanceContextProvider>
      </ReachStatsContext.Provider>
    </>
  )
}
