import React, { useState } from 'react'
import type { IntlShape } from 'react-intl'
import { FormattedMessage, useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import EmailIcon from '@mui/icons-material/EmailOutlined'
import VisibilityOff from '@mui/icons-material/VisibilityOffOutlined'
import Visibility from '@mui/icons-material/VisibilityOutlined'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import MUILink from '@mui/material/Link'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { addBreadcrumb } from '@sentry/core'
import { useFormik } from 'formik'
import curry from 'lodash/curry'
import { useFormatMessage } from '@hooks/util'
import { signIn } from '../actions/authentication'
import { OLD_MIN_PASSWORD_LENGTH } from '../constants'
import ErrorMessage from '../jobz_ui_components/ErrorMessage'
import { getKnownLoginErrors } from '../reducers/authentication'
import { ErrorObject } from '../types'
import serializeError from '../utils/serializeError'
import { email as validateEmail, password as validatePassword } from '../validations'

type StateProps = {
  isSubmitting: boolean
  errors: string[]
}

type DispatchProps = {
  handleSubmitForm: (arg0: { email: string; password: string }) => Promise<void>
}

type SignInFormProps = StateProps & DispatchProps

type FormData = {
  email: string
  password: string
}

const validateWithIntl = curry((intl: IntlShape, values: FormData) => {
  const errors: ErrorObject = {}
  const email = validateEmail(values.email)
  const password = validatePassword(values.password, OLD_MIN_PASSWORD_LENGTH)

  if (email) {
    errors.email = intl.formatMessage({
      id: email,
    })
  }

  if (password) {
    errors.password = intl.formatMessage(
      {
        id: password,
      },
      {
        num: OLD_MIN_PASSWORD_LENGTH,
      }
    )
  }

  return errors
})

const _SignInForm = ({ isSubmitting, errors, handleSubmitForm }: SignInFormProps) => {
  const intl = useIntl()
  const [showPassword, setShowPassword] = useState(false)
  const formatMessage = useFormatMessage()
  const formik = useFormik<FormData>({
    initialValues: {
      email: '',
      password: '',
    },
    validate: validateWithIntl(intl),
    onSubmit: handleSubmitForm,
  })

  const handleClickShowPassword = () => {
    setShowPassword((v) => !v)
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          id="email"
          name="email"
          variant="outlined"
          margin="normal"
          size="small"
          fullWidth
          label={formatMessage('email')}
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <EmailIcon color="inherit" />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          id="password"
          name="password"
          type={showPassword ? 'text' : 'password'}
          variant="outlined"
          margin="normal"
          size="small"
          fullWidth
          label={formatMessage('password')}
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  sx={{ mr: '-3px' }}
                  size="small"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {errors.map((error) => (
          <ErrorMessage key={error} text={formatMessage(error)} />
        ))}
        <Button
          id="submitButton"
          type="submit"
          variant="contained"
          color="primary"
          size="medium"
          fullWidth
          disabled={isSubmitting}
          sx={{ mt: 1, mb: 2 }}
        >
          <FormattedMessage id="employer_sign_in_submit_button" />
        </Button>
      </form>

      <Box mb={3} display="flex" alignItems="center" justifyContent="center">
        <Typography variant="body2" color="textSecondary">
          <FormattedMessage id="password_forgot" />
        </Typography>
        <MUILink to={`/${intl.locale}/forgot-password`} variant="subtitle2" ml={0.5} component={Link} underline="hover">
          <FormattedMessage id="reset_password" />
        </MUILink>
      </Box>
    </>
  )
}

const mapStateToProps = (state) => ({
  isSubmitting: state.authentication.tryingToLogin,
  errors: getKnownLoginErrors(state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  async handleSubmitForm(values) {
    try {
      await dispatch(signIn(values.email.trim().toLowerCase(), values.password))
    } catch (err) {
      console.error(err)
      if (err instanceof Error) {
        addBreadcrumb({
          category: 'auth',
          message: `Failed to log in`,
          level: 'info',
          data: { props: ownProps, message: err.message, error: serializeError(err) },
        })
      }
    }
  },
})

const SignInForm = connect<StateProps, DispatchProps, object, any>(mapStateToProps, mapDispatchToProps)(_SignInForm)
export default SignInForm
