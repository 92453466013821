import React from 'react'
import { ClearOutlined } from '@mui/icons-material'
import { Box, Grid, IconButton, InputAdornment, TextField } from '@mui/material'
import { FieldMetaProps } from 'formik'
import { useFormatMessage } from '@hooks/util'
import Dropdown from '../../../../ui_components/Dropdown'
import hasError from '../../../../utils/hasError'
import Dealbreaker from '../../Dealbreaker'
import { getMenuItems, OperatorOption } from '../../ScreeningQuestionsForm/utils'
import useNumberSQFields from '../useNumberSQFields'
import PreferredAnswerConditionTitle from './PreferredAnswerConditionTitle'

export default function AnswerSectionNumberSQ({ index }: { index: number }) {
  const formatMessage = useFormatMessage()
  const fieldNamePrefix = `screeningQuestions[${index}].preferred_answer_condition`

  const {
    clearEndNumber,
    clearStartNumber,
    endNumber,
    endNumberMeta,
    handleClearOperator,
    onChangeEndNumber,
    onChangeOperator,
    onChangeStartNumber,
    operator,
    operatorMeta,
    startNumber,
    startNumberMeta,
  } = useNumberSQFields(fieldNamePrefix)

  const isBetween = operator === OperatorOption.BETWEEN

  const getNumberInputProps = (meta: FieldMetaProps<string | null>, onClear: () => void) => ({
    size: 'small' as const,
    fullWidth: true,
    InputLabelProps: { shrink: true },
    error: hasError(meta),
    helperText: hasError(meta) && formatMessage(meta.error || ''),
    InputProps: {
      endAdornment:
        meta.value === null ? undefined : (
          <InputAdornment position="end">
            <IconButton edge="end" size="small" onClick={onClear}>
              <ClearOutlined fontSize="small" />
            </IconButton>
          </InputAdornment>
        ),
    },
  })

  return (
    <Box>
      <Box m={2} mt={1}>
        <PreferredAnswerConditionTitle />
        <Grid container columnSpacing={2} mt={2}>
          <Grid item md={isBetween ? 6 : 8}>
            <Dropdown
              value={operator}
              name={`${fieldNamePrefix}.operator`}
              label={formatMessage('employer_job_form_screening_question_answer_section_date_timeframe_label')}
              onChange={onChangeOperator}
              showClearIcon={!!operator}
              onClear={handleClearOperator}
              error={hasError(operatorMeta)}
              items={getMenuItems(formatMessage, 'employer_job_form_screening_question_answer_section_number_option')}
              InputLabelProps={{
                shrink: false,
                sx: { visibility: operator ? 'hidden' : 'visible' },
              }}
            />
          </Grid>

          <Grid item md={isBetween ? 6 : 4}>
            <Box display="flex" gap={1} alignItems="start">
              <TextField
                name={`${fieldNamePrefix}.value1`}
                value={startNumber}
                onChange={onChangeStartNumber}
                label={formatMessage('employer_job_form_screening_question_answer_section_number_value_label')}
                disabled={!operator}
                {...getNumberInputProps(startNumberMeta, clearStartNumber)}
              />
              {isBetween && (
                <>
                  <Box display="flex" alignItems="center" justifyContent="center" height={40}>
                    <span>-</span>
                  </Box>
                  <TextField
                    name={`${fieldNamePrefix}.value2`}
                    value={endNumber}
                    onChange={onChangeEndNumber}
                    label={formatMessage('employer_job_form_screening_question_answer_section_number_value_label')}
                    disabled={!operator}
                    {...getNumberInputProps(endNumberMeta, clearEndNumber)}
                  />
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Dealbreaker
        inputName={`screeningQuestions[${index}].dealbreaker`}
        label={formatMessage('employer_job_form_screening_question_date_dealbreaker_label')}
      />
    </Box>
  )
}
