import { v1 } from 'uuid'
import { SQ_TYPE } from '../../constants'
import getTranslation from './form/utils/getTranslation'
import type {
  CatalogQuestionAnswer,
  CatalogScreeningQuestion,
  PossibleQuestionAnswer,
  ScreeningQuestionWithCustomKey,
} from '../../types'

function catalogQuestionAnswerToPossibleQuestionAnswer({
  catalogQuestionAnswer,
  locale,
  localeVariant,
  index,
}: {
  catalogQuestionAnswer: CatalogQuestionAnswer
  locale: string
  localeVariant: string
  index: number
}): PossibleQuestionAnswer & { __key: string } {
  return {
    __key: v1(),
    id: null,
    title: getTranslation({
      translations: catalogQuestionAnswer.translations,
      locale,
      localeVariant,
    }),
    position: index,
    preferred_answer: false,
    catalog_question_answer_id: catalogQuestionAnswer.id,
    qualification_mapping: catalogQuestionAnswer.qualification_mapping,
  }
}

export default function catalogQuestionToScreeningQuestion({
  catalogQuestion,
  locale,
  localeVariant,
}: {
  catalogQuestion: CatalogScreeningQuestion
  locale: string
  localeVariant: string
}): ScreeningQuestionWithCustomKey {
  return {
    __key: v1(),
    id: null,
    job_id: '',
    title: getTranslation({
      translations: catalogQuestion.translations,
      locale,
      localeVariant,
    }),
    // Catalog questions include 'certificate' question type.
    question_type:
      // However, this question type isn't valid for screening question.
      // So, we need to change it. This question is still a single select question,
      // but it can be customized (add/change/remove answers).
      catalogQuestion.question_type === SQ_TYPE.CERTIFICATE ? SQ_TYPE.SINGLE_SELECT : catalogQuestion.question_type,
    mandatory: true,
    dealbreaker: false,
    sorting_position: 0,
    catalog_question_id: catalogQuestion.id,
    is_sticky: catalogQuestion.is_sticky || false,
    no_preferred_answer: catalogQuestion.no_preferred_answer || false,
    possible_screening_question_answers: catalogQuestion.catalog_question_answers.map((answer, i) =>
      catalogQuestionAnswerToPossibleQuestionAnswer({
        catalogQuestionAnswer: answer,
        locale,
        localeVariant,
        index: i,
      })
    ),
    preferred_answer_condition: catalogQuestion.preferred_answer_condition,
    screening_question_deletable: true,
    screening_question_editable: false,
  }
}
