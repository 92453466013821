import React from 'react'
import { DeleteOutline } from '@mui/icons-material'
import { Box, Checkbox, IconButton } from '@mui/material'
import { Field, useField } from 'formik'
import { useFormatMessage } from '@hooks/util'
import hasError from '../../../../utils/hasError'
import DebouncedFormTextField from '../../../DebouncedFormTextField'

type CustomAnswerOptionProps = {
  questionIndex: number
  answerIndex: number
  handleOnChange: (event: React.ChangeEvent<HTMLInputElement>, callback?: (value: unknown) => unknown) => void
  onRemove: () => void
  disabled: boolean
  titleEditable?: boolean
}

type SingleSelectCustomTitleProps = {
  questionIndex: number
  answerIndex: number
}

export const SingleSelectCustomTitle = ({
  questionIndex,
  answerIndex,
  disabled,
  titleEditable = true,
}: SingleSelectCustomTitleProps & {
  disabled: boolean
  titleEditable?: boolean
}) => {
  const formatMessage = useFormatMessage()
  return (
    <Field name={`screeningQuestions.${questionIndex}.possible_screening_question_answers.${answerIndex}.title`}>
      {({ field, meta, form: { setFieldTouched, setFieldValue } }) => (
        <DebouncedFormTextField
          id={`screening_questions_${questionIndex}_${answerIndex}_title`}
          name={field.name}
          value={field.value || ''}
          setFieldTouched={setFieldTouched}
          setFieldValue={setFieldValue}
          onBlur={field.onBlur}
          error={hasError(meta)}
          helperText={hasError(meta) && formatMessage(meta.error)}
          placeholder={formatMessage('employer_job_form_screening_question_single_select_placeholder')}
          disabled={disabled}
          inputProps={{ readOnly: !titleEditable }}
          ms={10}
          fullWidth
          sx={{ py: '2px' }}
        />
      )}
    </Field>
  )
}

const CustomAnswerOption = ({
  questionIndex,
  answerIndex,
  handleOnChange,
  onRemove,
  disabled,
  titleEditable = true,
}: CustomAnswerOptionProps) => {
  const [preferredAnswer] = useField(
    `screeningQuestions.${questionIndex}.possible_screening_question_answers.${answerIndex}.preferred_answer`
  )

  return (
    <Box
      data-testid={`single-select-answer-${answerIndex}`}
      display="flex"
      alignItems="start"
      justifyContent="space-between"
    >
      <Checkbox
        id={preferredAnswer.name}
        name={preferredAnswer.name}
        checked={preferredAnswer.value}
        value={preferredAnswer.value}
        onChange={(event) => handleOnChange(event, preferredAnswer.onChange)}
        sx={{ my: '1px' }}
      />
      <SingleSelectCustomTitle
        questionIndex={questionIndex}
        answerIndex={answerIndex}
        disabled={disabled}
        titleEditable={titleEditable}
      />
      <IconButton
        id={`screening_questions-${questionIndex}-single_select-${answerIndex}-delete`}
        onClick={onRemove}
        disabled={disabled}
        size="large"
      >
        <DeleteOutline fontSize="small" />
      </IconButton>
    </Box>
  )
}

export default CustomAnswerOption
