import React, { useCallback, useContext } from 'react'
import Box from '@mui/material/Box'
import HireDataDownloadButton from '../../../pages/Statistics/Hire/components/HireDataDownloadButton'
import {
  HireJobsPerformanceContext,
  HireJobsPerformanceContextProvider,
} from '../../../pages/Statistics/Hire/contexts/HireJobsPerformanceContext'
import { trackingIds } from '../../../tracking/constants'
import { HireJobStatistics } from '../../../types'
import JobsPerformanceFooter from '../common/JobsPerformanceFooter'
import JobsPerformanceWrapper from '../common/JobsPerformanceWrapper'
import PerformanceHeader from '../common/PerformanceHeader'
import PerformanceToolbar from '../common/PerformanceToolbar'
import HirePublicationHistory from '../HirePublicationHistory'
import usePublicationHistoryDialog from '../HirePublicationHistory/hooks/usePublicationHistoryDialog'
import useHireJobsDatagrid from './useHireJobsDatagrid'

const ROW_HEIGHT = 53

const _HireJobsPerformanceStatistics = () => {
  const { pagination, setPagination } = useContext(HireJobsPerformanceContext)
  const publicationHistoryDialog = usePublicationHistoryDialog()

  const handleRowClick = useCallback(
    (row: HireJobStatistics, event: React.MouseEvent<HTMLTableRowElement>) => {
      const rect = event.currentTarget.getBoundingClientRect()
      const parentTop = event.currentTarget.parentElement?.getBoundingClientRect()?.top ?? 0

      // Calculate the position relative to the parent element
      const dialogPosition = rect.bottom - parentTop + ROW_HEIGHT * 4

      publicationHistoryDialog.openDialog(row, dialogPosition)
    },
    [publicationHistoryDialog]
  )

  const table = useHireJobsDatagrid({
    onRowClick: handleRowClick,
  })

  const Header = useCallback(
    () => (
      <PerformanceHeader
        table={table}
        titleId="reach_stats_jobs_overview_title"
        tooltipId="reach_stats_jobs_overview_title_tooltip"
        extraComponents={<HireDataDownloadButton variant="text" />}
        trackingId={trackingIds.statistics.hire.jobsPerformanceStatistics.toggleColumns}
      />
    ),
    [table]
  )

  const Toolbar = useCallback(() => <PerformanceToolbar table={table} superTitleIndex={6} />, [table])

  const Footer = useCallback(
    () => (
      <JobsPerformanceFooter
        table={table}
        pagination={pagination}
        setPagination={setPagination}
        rowsPerPageOptions={[5, 10, 25, 50]}
      />
    ),
    [table, pagination, setPagination]
  )

  return (
    <Box position="relative">
      <JobsPerformanceWrapper<HireJobStatistics> table={table} Header={Header} Footer={Footer} Toolbar={Toolbar} />
      <HirePublicationHistory
        isOpen={publicationHistoryDialog.isOpen}
        onClose={publicationHistoryDialog.handleClose}
        selectedJob={publicationHistoryDialog.selectedJob}
        initialPosition={publicationHistoryDialog.position.y}
      />
    </Box>
  )
}

export default function HireJobsPerformanceStatistics() {
  return (
    <Box height={798}>
      <HireJobsPerformanceContextProvider>
        <_HireJobsPerformanceStatistics />
      </HireJobsPerformanceContextProvider>
    </Box>
  )
}
