import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'
import FileSaver from 'file-saver'
import moment from 'moment/moment'
import { useCurrentCompany } from '@hooks/useCompanies'
import { ExportTypeHeader } from '../actions/api/services/genericTypes'

type QuerySort = {
  key: string
  direction: 'asc' | 'desc'
} | null

export type ExportType = 'pdf' | 'xlsx' | 'csv'

const exportTypesMap: Record<ExportType, ExportTypeHeader> = {
  pdf: 'application/pdf',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  csv: 'text/csv',
}

interface UseExportStatisticsProps<TFilters> {
  exportFn: (companyId: string, filters: TFilters, header: ExportTypeHeader) => Promise<Blob>
  filters: TFilters
  fileNamePrefix: string
  querySort?: QuerySort
}

export function useExportStatistics<TFilters>({
  exportFn,
  filters,
  fileNamePrefix,
  querySort,
}: UseExportStatisticsProps<TFilters>) {
  const { id: companyId } = useCurrentCompany()

  const convertDate = useCallback((date: string | null) => moment.utc(date, 'DD-MM-YYYY').format('YYYY_MM_DD'), [])

  const { mutate, isPending } = useMutation({
    mutationFn(selectedExportType: ExportType) {
      const apiFilters = {
        ...(filters as Record<string, any>),
        ...(querySort && { sort: querySort }),
      }
      return exportFn(companyId, apiFilters as TFilters, exportTypesMap[selectedExportType])
    },
    onSuccess(data, selectedExportType: ExportType) {
      const filterRecord = filters as Record<string, any>
      const from_to =
        filterRecord.from && filterRecord.to
          ? `_${convertDate(filterRecord.from)}_${convertDate(filterRecord.to)}`
          : '_all_time'
      const fileName = `${fileNamePrefix}${from_to}.${selectedExportType}`
      const file = new File([data as BlobPart], fileName, {
        type: exportTypesMap[selectedExportType],
      })
      FileSaver.saveAs(file)
    },
    onError(error) {
      console.error('Error exporting statistics:', error)
    },
  })

  return {
    downloadAs: mutate,
    exportTypesMap,
    isPending,
  }
}
