import React from 'react'
import CustomAnswerOption from './CustomAnswerOption'
import PossibleAnswerOption from './PossibleAnswerOption'

type AnswerOptionProps = {
  questionIndex: number
  answerIndex: number
  showPreferred: boolean
  isCustomQuestion: boolean
  isCustomizable: boolean
  remove: (index: number) => unknown
  onChange?: (checked: boolean) => unknown
  titleEditable?: boolean
}

const AnswerOption = ({
  questionIndex,
  answerIndex,
  showPreferred,
  isCustomizable,
  isCustomQuestion,
  remove,
  onChange,
  titleEditable = true,
}: AnswerOptionProps) => {
  const disabled = !isCustomQuestion && answerIndex === 0

  const onRemove = () => {
    remove(answerIndex)
  }

  function handleOnChange(event: React.ChangeEvent<HTMLInputElement>, callback?: (value: unknown) => unknown) {
    onChange?.(event.target.checked)
    callback?.(event)
  }

  return isCustomizable ? (
    <CustomAnswerOption
      questionIndex={questionIndex}
      answerIndex={answerIndex}
      onRemove={onRemove}
      disabled={disabled}
      handleOnChange={handleOnChange}
      titleEditable={titleEditable}
    />
  ) : (
    <PossibleAnswerOption
      questionIndex={questionIndex}
      answerIndex={answerIndex}
      handleOnChange={handleOnChange}
      showPreferred={showPreferred}
    />
  )
}

export default AnswerOption
