import { DatesFilters, HireCtaFlag, HireJobStatistics, HireStats, JobsFilters } from '../../../types'
import { getRequestRP } from '../utils/ApiRequests'
import { PaginatedResponse, PaginationParams, SortParams, ExportTypeHeader } from './genericTypes'

export const HireStatsService = {
  jobsGroupStats: (companyId: string, payload: JobsFilters & DatesFilters) =>
    getRequestRP<HireStats>(`/companies/${companyId}/statistics/hire/jobs`, payload),
  ctaFlags: (companyId: string, payload: JobsFilters) =>
    getRequestRP<HireCtaFlag[]>(`/companies/${companyId}/statistics/hire/jobs/cta_flags`, payload),
  jobsStats: (companyId: string, payload: JobsFilters & DatesFilters & PaginationParams & SortParams) =>
    getRequestRP<PaginatedResponse<HireJobStatistics[]>>(
      `/companies/${companyId}/statistics/hire/jobs/overview`,
      payload
    ),
  exportJobsOverview: (companyId: string, payload: Record<string, any> & SortParams, exportType: ExportTypeHeader) =>
    getRequestRP<Blob>(`/companies/${companyId}/statistics/hire/jobs/overview/export`, payload, {
      headers: { Accept: exportType },
      config: { responseType: 'arraybuffer' },
    }),
}
