import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import {
  NOT_ENOUGH_CHOICES_PROVIDED_ERROR,
  QUALIFICATION_SELECT_PREFERRED_ANSWER,
  REQUIRED_FIELD_ERROR,
} from '../../../../constants'
import { Locale } from '../../../../types'
import * as validations from '../../../../validations'
import {
  questionIsDate,
  questionIsFileUpload,
  questionIsFreeText,
  questionIsMultiSelect,
  questionIsNumber,
  questionIsSingleSelect,
  questionIsYesNo,
} from './getQuestionType'
import type {
  CatalogScreeningQuestion,
  PossibleQuestionAnswer,
  PreferredAnswerCondition,
  ScreeningQuestionWithCustomKey,
} from '../../../../types'

type ScreeningQuestionFormData = {
  localeVariant: string
  screeningQuestions: ScreeningQuestionWithCustomKey[]
}
type ScreeningQuestionValidationType = {
  title: string | null | undefined
  question_type: string | null | undefined
  dealbreaker: string | null | undefined
  possible_screening_question_answers:
    | Array<{
        title: string | null | undefined
      }>
    | null
    | undefined
  preferred_answer_condition: Partial<PreferredAnswerCondition> | null | undefined
}
type ScreeningQuestionFormValidationType = {
  screeningQuestions: ScreeningQuestionValidationType[]
}

const NUMBER_VALIDATION_REGEX_DE = /^(?:\d{1,3}(?:\.\d{3})*|\d+)$/
const NUMBER_VALIDATION_REGEX_EN = /^(?:\d{1,3}(?:,\d{3})*|\d+)$/

export const validateForm = (
  values: ScreeningQuestionFormData,
  catalogQuestions: Record<string, CatalogScreeningQuestion[]>,
  locale: Locale = 'de'
): Record<string, any> => {
  // @ts-expect-error - TS2741 - Property 'screeningQuestions' is missing in type '{}' but required in type 'ScreeningQuestionFormValidationType'.
  const errors: ScreeningQuestionFormValidationType = {}
  const catalogQualificationsQuestionsById =
    catalogQuestions.qualifications?.reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {}) || {}
  // @ts-expect-error - TS2322 - Type 'Record<string, any>[]' is not assignable to type 'ScreeningQuestionValidationType[]'.
  const screeningQuestionsErrors: ScreeningQuestionValidationType[] = values.screeningQuestions.map((question, index) =>
    validateScreeningQuestion(
      index,
      values.screeningQuestions,
      question,
      isCatalogQualificationQuestion(question, catalogQualificationsQuestionsById),
      locale
    )
  )

  if (screeningQuestionsErrors.filter((err) => !isEmpty(err)).length) {
    errors.screeningQuestions = screeningQuestionsErrors
  }

  return errors
}

const checkDealbreakerSetWithNoPreferredAnswers = ({
  preferred_answer_condition,
  question_type,
  possible_screening_question_answers,
  dealbreaker,
}: ScreeningQuestionWithCustomKey): string | null | undefined => {
  const hasNoPreferredAnswerCondition = !preferred_answer_condition?.operator
  const hasNoPreferredAnswers = !hasAnyPreferredAnswerOptionSelected(possible_screening_question_answers, question_type)

  if (dealbreaker && hasNoPreferredAnswerCondition && hasNoPreferredAnswers) {
    return 'dealbreaker_true_but_no_preferred_answers_provided_error'
  }

  return null
}

const validateDateInputValues = (preferredAnswerCondition: Partial<PreferredAnswerCondition> | null) => {
  const { operator, value1: startDate, value2: endDate } = preferredAnswerCondition || {}
  const errors: Record<string, string> = {}

  if (!startDate) {
    errors.value1 = REQUIRED_FIELD_ERROR
  }

  if (operator === 'between') {
    if (!endDate) {
      errors.value2 = REQUIRED_FIELD_ERROR
    }

    if (moment(startDate).isValid() && moment(endDate).isValid() && moment(startDate).isAfter(moment(endDate))) {
      errors.value1 = 'preferred_answer_condition_date_range_error'
      errors.value2 = 'preferred_answer_condition_date_range_error'
    }
  }

  return errors
}

export function validateNumberInput(numberInput: string, locale: Locale): boolean {
  const thousandsSeparatorRegex = locale === 'de' ? NUMBER_VALIDATION_REGEX_DE : NUMBER_VALIDATION_REGEX_EN

  if (!thousandsSeparatorRegex.test(numberInput)) {
    return false
  }

  const numberWithoutSeparator = Number(numberInput.replace(/[,\.]/g, ''))
  if (!Number.isInteger(numberWithoutSeparator)) {
    return false
  }

  if (numberWithoutSeparator < 0) {
    return false
  }

  return true
}

export const validateNumberInputValues = (
  preferredAnswerCondition: Partial<PreferredAnswerCondition> | null,
  locale: Locale
) => {
  const { operator, value1: startNumber, value2: endNumber } = preferredAnswerCondition || {}
  const isBetweenOperator = operator === 'between'
  const errors: Record<string, string> = {}

  if (!startNumber) {
    errors.value1 = REQUIRED_FIELD_ERROR
  }

  if (isBetweenOperator && !endNumber) {
    errors.value2 = REQUIRED_FIELD_ERROR
  }

  if (startNumber && !validateNumberInput(startNumber, locale)) {
    errors.value1 = 'preferred_answer_condition_not_valid_whole_number_error'
  }

  if (isBetweenOperator && endNumber) {
    if (!validateNumberInput(endNumber, locale)) {
      errors.value2 = 'preferred_answer_condition_not_valid_whole_number_error'
      return errors
    }

    if (startNumber) {
      const startNumberWithoutSeparator = Number(startNumber.replace(/[,\.]/g, ''))
      const endNumberWithoutSeparator = Number(endNumber.replace(/[,\.]/g, ''))

      if (startNumberWithoutSeparator >= endNumberWithoutSeparator) {
        errors.value1 = 'preferred_answer_condition_number_range_error'
        errors.value2 = 'preferred_answer_condition_number_range_error'
      }
    }
  }

  return errors
}

const checkPreferredAnswerCondition = (values: ScreeningQuestionWithCustomKey, locale: Locale) => {
  if (!values.preferred_answer_condition?.operator) {
    return null
  }

  let errors: Record<string, string> = {}

  if (questionIsDate(values.question_type)) {
    errors = validateDateInputValues(values.preferred_answer_condition)
  }

  if (questionIsNumber(values.question_type)) {
    errors = validateNumberInputValues(values.preferred_answer_condition, locale)
  }

  return isEmpty(errors) ? null : errors
}

const checkAnswersOptionsAmount = (
  answersOptions: PossibleQuestionAnswer[],
  questionType: string
): [string | null | undefined, Array<string | null | undefined>] => {
  if (questionIsSingleSelect(questionType) || questionIsMultiSelect(questionType)) {
    const { errorName, errors } = validations.screeningQuestion.multiple_choices(answersOptions)

    if (errorName && !answersOptions) {
      return [errorName, []]
    }

    return [errorName, errors || []]
  }

  return [null, []]
}

export const assignSingleSelectMissingFieldValidationError = (
  singleSelectOptionsErrors: Array<string | null | undefined>
): Array<{
  title: string | null | undefined
}> =>
  singleSelectOptionsErrors.map((error) => ({
    title: error || null,
  }))

function isCatalogQualificationQuestion(
  question: ScreeningQuestionWithCustomKey,
  catalogQuestionsById: Record<number, CatalogScreeningQuestion>
) {
  if (!question.catalog_question_id) {
    return false
  }
  return catalogQuestionsById.hasOwnProperty(question.catalog_question_id)
}

export const validateScreeningQuestion = (
  index: number,
  screeningQuestions: ScreeningQuestionWithCustomKey[],
  values: ScreeningQuestionWithCustomKey,
  isCatalogQualificationQuestion: boolean,
  locale: Locale
): Record<string, any> => {
  // @ts-expect-error - TS2739 - Type '{}' is missing the following properties from type 'ScreeningQuestionValidationType': title, question_type, preferred_answer, dealbreaker, possible_screening_question_answers
  const errors: ScreeningQuestionValidationType = {}
  const screeningQuestionsTitle: Array<string> = screeningQuestions
    .filter((_, i) => i !== index)
    .map((question) => question.title)
  const title = validations.screeningQuestion.title(screeningQuestionsTitle, values.title)
  const questionType = validations.screeningQuestion.question_type(values.question_type)

  if (title) {
    errors.title = title
  }
  if (questionType) {
    errors.question_type = questionType
  }

  /* Depending on the question type, we have slightly differing validations, and differing places to display the validation messages */
  const [answersOptionsError, answersOptionsErrors]: [
    string | null | undefined,
    Array<string | null | undefined>
  ] = checkAnswersOptionsAmount(values.possible_screening_question_answers, values.question_type)

  const dealbreaker = checkDealbreakerSetWithNoPreferredAnswers(values)
  const preferredAnswerConditionErrors = checkPreferredAnswerCondition(values, locale)

  if (dealbreaker) {
    errors.dealbreaker = dealbreaker
  }

  if (answersOptionsError === NOT_ENOUGH_CHOICES_PROVIDED_ERROR && !values.catalog_question_id) {
    errors.question_type = answersOptionsError
  } else if (
    hasAnswersOptionsErrors(answersOptionsErrors) &&
    assignSingleSelectMissingFieldValidationError(answersOptionsErrors)
  ) {
    errors.possible_screening_question_answers = assignSingleSelectMissingFieldValidationError(answersOptionsErrors)
  }

  if (preferredAnswerConditionErrors) {
    errors.preferred_answer_condition = preferredAnswerConditionErrors
  }

  if (isCatalogQualificationQuestion) {
    if (!hasAnyPreferredAnswerOptionSelected(values.possible_screening_question_answers, values.question_type)) {
      errors.dealbreaker = QUALIFICATION_SELECT_PREFERRED_ANSWER
    }
  }

  return errors
}

const hasAnswersOptionsErrors = (errors: Array<string | null | undefined>) => errors && errors.length > 0

const hasAnyPreferredAnswerOptionSelected = (answersOptions: PossibleQuestionAnswer[] = [], questionType: string) =>
  questionIsFreeText(questionType) || questionIsFileUpload(questionType) || questionIsYesNo(questionType)
    ? true
    : answersOptions.some((option) => option.preferred_answer)
