import { useMemo } from 'react'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { useAppSelector } from '@hooks/store'
import { CompanyService } from '../../actions/api/services/companyService'
import { prepareCompanyConditions } from '../../conditions/companyConditions'
import CompanyStorage from '../../services/companyStorage'
import { changeHotJarUser } from '../../store/middleware/hotJarMiddleware'
import { Company, CompanyStatistics } from '../../types'
import nextTick from '../../utils/nextTick'

export const CompaniesQueryKeys = {
  companiesList: 'COMPANIES_LIST',
  companyDetails: 'COMPANY_DETAILS',
  companyStatistics: 'COMPANY_STATISTICS',
}

export const useCompaniesList = () => {
  const user = useAppSelector((state) => state.authentication.user)
  return useQuery<Company[]>({
    queryKey: [CompaniesQueryKeys.companiesList],
    queryFn: CompanyService.list,
    enabled: !!user,
    refetchOnWindowFocus: false,
  })
}

function useCompanyId(): string | null {
  const storedCompanyId = CompanyStorage.getCurrentCompanyId()
  const { data: companies, isSuccess: companyListLoaded } = useCompaniesList()

  return useMemo(() => {
    if (!companyListLoaded) {
      return null
    }
    if (companies.length === 1) {
      return companies[0].id
    }
    if (companies.some((company) => company.id === storedCompanyId)) {
      return storedCompanyId
    }
    return null
  }, [companies, storedCompanyId, companyListLoaded])
}

export const useCurrentCompanyQuery = () => {
  const { isSuccess: companyListLoaded } = useCompaniesList()
  const recruiter = useAppSelector((state) => state.authentication.user)

  const companyId = useCompanyId()

  function notifyHotJar(company: Company) {
    changeHotJarUser(recruiter!, {
      company_id: company.id,
      company_is_ssu: company.self_service_sign_up,
      company_name: company.name,
      company_client_segment: company.client_segment,
      recruiter_first_name: recruiter!.first_name,
      recruiter_last_name: recruiter!.last_name,
    })
  }

  return useQuery<Company>({
    queryKey: [CompaniesQueryKeys.companyDetails, companyId],
    async queryFn() {
      const company = await CompanyService.get(companyId!)
      nextTick(() => notifyHotJar(company))
      return company
    },
    enabled: !!companyId && companyListLoaded,
    select: (data): Company => ({
      ...data,
      ...prepareCompanyConditions(data),
    }),
  })
}

export const useCurrentCompany = () => {
  const { data } = useCurrentCompanyQuery()

  return data as Company
}

const defaultCompanyStatistics: CompanyStatistics = {
  total_views: 0,
  total_candidates: 0,
  total_jobs: 0,
  online_jobs: 0,
  draft_jobs: 0,
  expired_jobs: 0,
  organic_jobs: 0,
  marketed_jobs: 0,
  hire_total_views: 0,
  hire_total_candidates: 0,
  hire_marketed_jobs: 0,
  hire_draft_jobs: 0,
  reach_total_views: 0,
  reach_total_candidates: 0,
  reach_marketed_jobs: 0,
  reach_draft_jobs: 0,
  ever_had_live_hire_jobs: false,
}

export const useCompanyStatistics = () => {
  const { isSuccess: companyListLoaded } = useCompaniesList()
  const companyId = useCompanyId()

  const { data, ...query } = useQuery<CompanyStatistics>({
    queryKey: [CompaniesQueryKeys.companyStatistics, companyId],
    queryFn: () => CompanyService.statistics(companyId!),
    enabled: !!companyId && companyListLoaded,
    refetchOnWindowFocus: false,
  })

  return {
    ...query,
    statistics: data || defaultCompanyStatistics,
  }
}

// TODO: this is just an example, further enhancement after usage
export const useUpdateCompany = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (data: { companyId: string; updateData: Record<string, any> }) =>
      CompanyService.update(data.companyId, data.updateData),
    onSuccess(data: Company) {
      queryClient.invalidateQueries({ queryKey: [CompaniesQueryKeys.companyDetails, data.id] })
    },
    onError(error) {
      console.error('API Error:', error.message)
    },
  })
}
