import React from 'react'
import Box from '@mui/material/Box'
import { useCompanyStatistics } from '@hooks/useCompanies'
import JobFilter from '../../../../components/FilterAndSort/Filters/JobFilter'
import JobLocationFilter from '../../../../components/FilterAndSort/Filters/JobLocationFilter'
import ProfessionFilter from '../../../../components/FilterAndSort/Filters/ProfessionFilter'
import FilterContainer from '../../components/FilterContainer'
import { useHireStatsFilters } from '../contexts/HireStatsContext'
import DateRangeFilter from './DateRangeFilter'
import HireDataDownloadButton from './HireDataDownloadButton'

function Filters() {
  const {
    filters,
    dateRange,
    handleDateRangeFilterChange,
    handleCityFilterChange,
    handleJobIdFilterChange,
    handleJobTitleFilterChange,
  } = useHireStatsFilters()

  const { statistics: companyStatistics } = useCompanyStatistics()
  if (!companyStatistics.ever_had_live_hire_jobs) {
    return null
  }

  return (
    <FilterContainer sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Box display="flex" gap="1em" flexWrap="wrap" alignItems="center">
        <JobFilter value={filters.job_uids} onChange={handleJobIdFilterChange} />
        <ProfessionFilter value={filters.b2c_job_title_ids} onChange={handleJobTitleFilterChange} />
        <JobLocationFilter value={filters.cities} onChange={handleCityFilterChange} />
        <DateRangeFilter value={dateRange} onChange={handleDateRangeFilterChange} />
      </Box>
      <HireDataDownloadButton />
    </FilterContainer>
  )
}

export default Filters
