import React, { useContext, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { useMarketingContractGroups } from '@hooks/useMarketingContractGroups'
import useActionTracker from '@hooks/util/useActionTracker'
import CampaignFilter from '../../../../components/FilterAndSort/Filters/CampaignFilter'
import DateRangeFilter from '../../../../components/FilterAndSort/Filters/DateRangeFilter'
import NonUpCasedButton from '../../../../components/FilterAndSort/NonUpCasedButton'
import { DateRange, DateRangeOption } from '../../../../components/FilterAndSort/types'
import { trackingIds } from '../../../../tracking/constants'
import { ReachStatsFilters } from '../../../../types'
import nextTick from '../../../../utils/nextTick'
import FilterContainer from '../../components/FilterContainer'
import { defaultState, ReachStatsContext } from '../contexts/ReachStatsContext'
import ReachDataDownloadButton from './ReachDataDownloadButton'

export default function Filters() {
  const { noCampaigns } = useMarketingContractGroups()

  if (noCampaigns) {
    return null
  }

  return <_Filters />
}

function _Filters() {
  const { filters, setFilters } = useContext(ReachStatsContext)
  const { trackCustomClick } = useActionTracker()

  const dateRange: DateRange = { from: filters.from, to: filters.to }

  const [showClearAll, setShowClearAll] = useState(false)

  function upsert(data: Partial<ReachStatsFilters>) {
    setFilters((state) => ({ ...state, ...data }))
  }

  function handleCampaignFilterChange(campaigns: string[]) {
    if (filters.marketing_contract_group_id) {
      setShowClearAll(true)
    }
    const campaign = campaigns[0]
    upsert({ marketing_contract_group_id: campaign })
    trackCustomClick({ trackingId: trackingIds.statistics.reach.filters.campaign, content: campaign })
  }

  function handleDateRangeFilterChange(range: DateRange, rawValue: DateRangeOption) {
    if (filters.from || filters.to) {
      setShowClearAll(true)
    }
    upsert({ from: range.from, to: range.to })
    trackCustomClick({
      trackingId: trackingIds.statistics.reach.filters.dateRange[rawValue.key!],
      content: `${range.from} - ${range.to}`,
    })
  }

  function handleClearAll() {
    setFilters(defaultState)
    trackCustomClick({ trackingId: trackingIds.statistics.reach.filters.clearAll })

    nextTick(() => {
      /* Wait for default values to be pushed back to the filters.
       * This is necessary to avoid showing the "Clear All" button again
       * when the default values are set.
       */
      setShowClearAll(false)
    })
  }

  return (
    <FilterContainer>
      <Box display="flex" gap="1em" alignItems="center">
        <CampaignFilter
          singleSelect
          tooltip={null}
          value={filters.marketing_contract_group_id ? [filters.marketing_contract_group_id] : []}
          onChange={handleCampaignFilterChange}
        />
        <DateRangeFilter value={dateRange} onChange={handleDateRangeFilterChange} />
        {showClearAll && (
          <Grid item>
            <NonUpCasedButton id="candidate-view-clear-all-button" onClick={handleClearAll}>
              <FormattedMessage id="candidate_view_clear_all_filters_button_text" />
            </NonUpCasedButton>
          </Grid>
        )}
      </Box>
      <ReachDataDownloadButton />
    </FilterContainer>
  )
}
