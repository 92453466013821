import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Box, Button, Typography, ButtonProps } from '@mui/material'
import { useFormatMessage } from '@hooks/util/useFormatMessage'
import SleepingJobster from '@images/sleepingJobster.svg?react'
import StaticLink from '../../../../jobz_ui_components/StaticLink'

interface NotLiveProps {
  titleId: string
  contentId: string
  showButton?: boolean
  buttonTextId?: string
  onButtonClick?: () => void
  addTopMargin?: boolean
  buttonProps?: ButtonProps
}

export default function NotLive({
  titleId,
  contentId,
  showButton = false,
  buttonTextId,
  onButtonClick,
  addTopMargin = false,
  buttonProps,
}: NotLiveProps) {
  const formatMessage = useFormatMessage()

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      p={2}
      gap={2}
      width={634}
      m="auto"
      mt={addTopMargin ? 10 : 0}
    >
      <SleepingJobster style={{ width: 152, height: 87 }} />
      <Typography variant="subtitle3" align="center" color="textPrimary">
        <FormattedMessage id={titleId} />
      </Typography>
      <Typography variant="body2" align="center" color="textSecondary" whiteSpace="pre-wrap">
        {formatMessage(contentId, {
          more: (label: string) => (
            <StaticLink newTab href="https://help.heyjobs.co/de-form/articles/9148674-unsere-produkte">
              {label}
            </StaticLink>
          ),
        })}
      </Typography>
      {showButton && buttonTextId && onButtonClick && (
        <Button color="primary" onClick={onButtonClick} {...buttonProps}>
          <FormattedMessage id={buttonTextId} />
        </Button>
      )}
    </Box>
  )
}
