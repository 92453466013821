import { IntlShape } from 'react-intl'
import { v1 } from 'uuid'
import { SQ_TYPE } from '../../constants'
import createJobIntl from './jobIntl'
import type {
  PossibleQuestionAnswerWithCustomKey,
  ScreeningQuestionType,
  ScreeningQuestionWithCustomKey,
} from '../../types'

function createBaseAnswer(position: number, title = ''): PossibleQuestionAnswerWithCustomKey {
  return {
    __key: v1(),
    id: null,
    title,
    position,
    preferred_answer: false,
    catalog_question_answer_id: null,
  }
}

function createNoneAnswer(position: number, intl: IntlShape): PossibleQuestionAnswerWithCustomKey {
  return createBaseAnswer(position, intl.formatMessage({ id: 'screening_question_answer_none' }))
}

const createCustomQuestionAnswers = (
  jobLocale: string,
  type: ScreeningQuestionType
): PossibleQuestionAnswerWithCustomKey[] => {
  const intl = createJobIntl(jobLocale)

  if (type === SQ_TYPE.YES_NO) {
    return [
      createBaseAnswer(0, intl.formatMessage({ id: 'yes' })),
      createBaseAnswer(1, intl.formatMessage({ id: 'no' })),
    ]
  }

  if (type === SQ_TYPE.SINGLE_SELECT) {
    return [createBaseAnswer(0), createBaseAnswer(1), createNoneAnswer(2, intl)]
  }

  if (type === SQ_TYPE.MULTI_SELECT) {
    return [createBaseAnswer(0), createBaseAnswer(1), createBaseAnswer(2), createNoneAnswer(3, intl)]
  }

  return []
}

function createPreferredAnswerCondition(type: ScreeningQuestionType) {
  if (type === SQ_TYPE.DATE || type === SQ_TYPE.NUMBER) {
    return {
      operator: null,
      value1: null,
      value2: null,
    }
  }

  return null
}

export default function createCustomQuestion(
  jobLocale: string,
  type: ScreeningQuestionType = ''
): ScreeningQuestionWithCustomKey {
  const intl = createJobIntl(jobLocale)
  return {
    __key: v1(),
    id: null,
    job_id: '',
    title: intl.formatMessage({
      id: 'employer_catalog_custom_question_title',
    }),
    question_type: type,
    mandatory: true,
    dealbreaker: false,
    sorting_position: 0,
    // this value will be adjusted based on question index in the array
    catalog_question_id: null,
    is_sticky: false,
    no_preferred_answer: false,
    possible_screening_question_answers: createCustomQuestionAnswers(jobLocale, type),
    preferred_answer_condition: createPreferredAnswerCondition(type),
    screening_question_deletable: true,
    screening_question_editable: true,
  }
}
