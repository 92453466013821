import React from 'react'
import { FormattedMessage, IntlShape, useIntl } from 'react-intl'
import { useFeatureValue } from '@growthbook/growthbook-react'
import {
  CheckBoxOutlined,
  DateRangeOutlined,
  LibraryAddCheckOutlined,
  NotesOutlined,
  PinOutlined,
  UploadFileOutlined,
} from '@mui/icons-material'
import { ListItemIcon, ListItemText, MenuItem, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { Field, useField } from 'formik'
import { useCurrentJob } from '@hooks/store'
import YesNoSQIcon from '@images/yes-no-sq-icon.svg?react'
import { DEFAULT_LOCALE, SQ_TYPE } from '../../constants'
import hasError from '../../utils/hasError'
import DebouncedFormTextField from '../DebouncedFormTextField'
import { FormSelectField, MenuItemProps } from '../FormFields'
import createCustomQuestion from './createCustomQuestion'
import { FormFieldHeaderStyle } from './form/ScreeningQuestionStyles'
import ProblematicWordsWarning from './ScreeningQuestion/ProblematicWordsWarning'
import type { ScreeningQuestion, ScreeningQuestionType } from '../../types'

type CustomQuestionProps = {
  index: number
  problematicWords: string[] | null
}
export const normalizeQuestionType = (type: any): ScreeningQuestionType =>
  [
    SQ_TYPE.SINGLE_SELECT,
    SQ_TYPE.YES_NO,
    SQ_TYPE.FREE_TEXT,
    SQ_TYPE.MULTI_SELECT,
    SQ_TYPE.FILE,
    SQ_TYPE.DATE,
    SQ_TYPE.NUMBER,
  ].includes(type)
    ? type
    : ''

const getSQTypesDropdownMenuItems = (showNewSQTypes: boolean = false, intl: IntlShape) => {
  const items = [
    {
      value: SQ_TYPE.YES_NO,
      icon: <YesNoSQIcon />,
      show: true,
    },
    {
      value: SQ_TYPE.MULTI_SELECT,
      icon: <LibraryAddCheckOutlined />,
      show: showNewSQTypes,
    },
    {
      value: SQ_TYPE.SINGLE_SELECT,
      icon: <CheckBoxOutlined />,
      show: true,
    },
    {
      value: SQ_TYPE.FREE_TEXT,
      icon: <NotesOutlined />,
      show: true,
    },
    {
      value: SQ_TYPE.NUMBER,
      icon: <PinOutlined />,
      show: showNewSQTypes,
    },
    {
      value: SQ_TYPE.DATE,
      icon: <DateRangeOutlined />,
      show: showNewSQTypes,
    },
    {
      value: SQ_TYPE.FILE,
      icon: <UploadFileOutlined />,
      show: showNewSQTypes,
    },
  ]
  return items
    .map(({ value, icon, show }) => ({
      value,
      icon,
      primaryText: intl.formatMessage({ id: `employer_job_form_screening_question_type_${value}` }),
      secondaryText: intl.formatMessage({ id: `employer_job_form_screening_question_type_${value}_desc` }),
      reactKey: `screening-question-type-menu-item-${value}`,
      show,
    }))
    .filter((item) => item.show)
}

export default function CustomQuestion({ index, problematicWords }: CustomQuestionProps) {
  const intl = useIntl()
  const job = useCurrentJob()
  const jobLocale = job?.locale || DEFAULT_LOCALE
  const [field, _, { setValue }] = useField<ScreeningQuestion>(`screeningQuestions.${index}`)
  const showNewSQTypes = useFeatureValue('rp_new_sq_types', false)

  const handleQuestionTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setValue({
      // Whenever the question_type changes, reset the previous values.
      // Different question_types affect some of the properties of the question,
      // e.g.: `free_text` cannot have `dealbreaker`, etc.
      ...createCustomQuestion(jobLocale, normalizeQuestionType(e.target.value)),
      // Need to make sure `id`, `__key` and `title` aren't overwritten.
      id: field.value.id,
      // @ts-expect-error - TS2345 - Argument of type '{ id: string | null | undefined; __key: any; title: string; job_id: string; question_type: ScreeningQuestionType; mandatory: boolean; dealbreaker: boolean; preferred_answer: "yes" | ... 2 more ... | null; sorting_position: number; catalog_question_id: number | ... 1 more ... | undefined; possible_screening_question_...' is not assignable to parameter of type 'ScreeningQuestion'. | TS2339 - Property '__key' does not exist on type 'ScreeningQuestion'.
      __key: field.value.__key,
      title: field.value.title,
    })
  }

  const renderSQTypeMenuItem = (menuItem: MenuItemProps) => {
    return (
      <MenuItem
        key={menuItem.value}
        value={menuItem.value}
        className="menuItem"
        data-testid={`screeningQuestionType-${index}--${menuItem.value}`}
        sx={{ alignItems: 'start' }}
      >
        <ListItemIcon>{menuItem.icon}</ListItemIcon>
        <ListItemText
          primary={menuItem.primaryText}
          secondary={
            <Typography variant="caption" color="textSecondary">
              {menuItem.secondaryText}
            </Typography>
          }
        />
      </MenuItem>
    )
  }

  return (
    <Box width="100%" p={2}>
      <Grid container spacing={2}>
        <Grid item md={8}>
          <Field name={`screeningQuestions.${index}.title`}>
            {({ field, meta, form: { setFieldTouched, setFieldValue } }) => (
              <DebouncedFormTextField
                id={`screeningQuestion-${index}`}
                name={field.name}
                value={field.value}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                onBlur={field.onBlur}
                fullWidth
                label={intl.formatMessage({
                  id: 'employer_job_form_screening_question_title_label',
                })}
                placeholder={intl.formatMessage({
                  id: 'employer_job_form_screening_question_title_placeholder',
                })}
                error={hasError(meta)}
                helperText={
                  hasError(meta) &&
                  intl.formatMessage({
                    id: meta.error,
                  })
                }
              />
            )}
          </Field>
        </Grid>
        <Grid item md={4}>
          <Field name={`screeningQuestions.${index}.question_type`}>
            {({ field, meta }) => (
              <FormSelectField
                id={`screeningQuestionType-${index}`}
                name={field.name}
                value={field.value}
                onChange={handleQuestionTypeChange}
                touched={meta.touched}
                error={meta.error}
                fullWidth
                menuItems={getSQTypesDropdownMenuItems(showNewSQTypes, intl)}
                renderMenuItem={showNewSQTypes ? renderSQTypeMenuItem : undefined}
                floatingLabelText={<FormattedMessage id="employer_job_form_screening_question_question_type_label" />}
                hintText={<FormattedMessage id="employer_job_form_screening_question_question_type_placeholder" />}
                floatingLabelStyle={FormFieldHeaderStyle}
              />
            )}
          </Field>
        </Grid>
      </Grid>
      <ProblematicWordsWarning problematicWords={problematicWords} />
    </Box>
  )
}
