import React, { useContext } from 'react'
import { useMarketingContractGroups } from '@hooks/useMarketingContractGroups'
import { ReachStatsService } from '../../../../actions/api/services/reachStatsService'
import StatisticsDataDownloadButton from '../../../../components/Statistics/common/StatisticsDataDownloadButton'
import { ReachStatsFilters } from '../../../../types'
import { JobsPerformanceContext } from '../contexts/JobsPerformanceContext'
import { ReachStatsContext } from '../contexts/ReachStatsContext'
import { useCampaignStats } from '../hooks/useReachStats'

interface CommonProps {
  variant?: 'outlined' | 'text'
}

export default function ReachDataDownloadButton({ variant = 'outlined' }: CommonProps) {
  const { noCampaigns } = useMarketingContractGroups()
  const { stats, isSuccess: isStatsLoaded } = useCampaignStats()
  const { filters } = useContext(ReachStatsContext)
  const { querySort } = useContext(JobsPerformanceContext)

  if (noCampaigns || !isStatsLoaded || !stats?.was_ever_live) {
    return null
  }

  return (
    <StatisticsDataDownloadButton<ReachStatsFilters>
      variant={variant}
      exportFn={ReachStatsService.exportJobsStatistics}
      filters={filters}
      fileNamePrefix="HeyJobs_Reach_Statistics"
      querySort={querySort}
    />
  )
}
