import React from 'react'
import { FormattedMessage } from 'react-intl'
import ArrowForward from '@mui/icons-material/ArrowForward'
import { Typography, Box } from '@mui/material'
import { useHireStats, useHireStatsHistory } from '@hooks/statistics/useHireStats'
import { useAppDispatch } from '@hooks/store/useAppDispatch'
import { useCompanyStatistics } from '@hooks/useCompanies'
import { useProductTab } from '@hooks/useProductTab'
import useChartJsPluginsRegistration from '@hooks/util/useChartJsPluginsRegistration'
import { useFormatMessage } from '@hooks/util/useFormatMessage'
import { updateDashboardState } from '../../../actions/creators'
import { InfoIconWithTooltip } from '../../../components/InfoIconWithTooltip'
import FunnelChart from '../../../components/Statistics/FunnelChart'
import HireJobsPerformanceStatistics from '../../../components/Statistics/HireJobsPerformanceStatistics'
import Loader from '../../../components/Statistics/Loader'
import SummaryContent from '../../../components/Statistics/Summary/SummaryContent'
import WarningPanel from '../../../components/Statistics/WarningPanel'
import TypographyWithIcon from '../../../components/TypographyWithIcon'
import { ProductTabs } from '../../../constants'
import NotLive from '../Reach/components/NotLive'
import Filters from './components/Filters'
import HireBestLeastPerformingJobs from './components/HireBestLeastPerformingJobs'
import HireCTASection from './components/HireCTASection'
import HireNumbersSection from './components/HireNumbersSection'
import { HireStatsContextProvider } from './contexts/HireStatsContext'

function _HireStats() {
  const { data: current, isLoading } = useHireStats()
  const { data: history } = useHireStatsHistory()
  const formatMessage = useFormatMessage()
  const { statistics: companyStatistics } = useCompanyStatistics()
  const dispatch = useAppDispatch()
  const { switchTab } = useProductTab()

  function switchToReach() {
    const newState = { productTab: ProductTabs.REACH, tab: 'marketed' }
    dispatch(updateDashboardState(newState, { reset: true }))
    switchTab(newState.productTab)
  }

  if (isLoading) {
    return (
      <Box height="30vh">
        <Loader />
      </Box>
    )
  }

  if (!companyStatistics.ever_had_live_hire_jobs) {
    return (
      <NotLive
        titleId="hire_stats_not_live_title"
        contentId="hire_stats_not_live_content"
        addTopMargin
        showButton
        buttonTextId="dashboard_hire_feed_goto_reach_btn"
        onButtonClick={switchToReach}
        buttonProps={{ endIcon: <ArrowForward /> }}
      />
    )
  }

  if (!current) {
    return null
  }

  const funnelData = {
    views: current?.views ?? 0,
    application_started_count: current?.application_started_count ?? 0,
    application_submitted_count: current?.application_submitted_count ?? 0,
    application_sent_count: current?.application_sent_count ?? 0,
    gold_application_count: current?.gold_application_count ?? 0,
  }
  return (
    <div>
      <WarningPanel warningFlags={current?.warning_flags ?? []} />
      <Box>
        <TypographyWithIcon
          variant="h6"
          mb={2}
          icon={<InfoIconWithTooltip tooltip={formatMessage('hire_stats_summary_section_title_tooltip')} />}
        >
          <FormattedMessage id="hire_stats_summary_section_title" />
        </TypographyWithIcon>
        <SummaryContent loading={isLoading}>
          <HireNumbersSection />
          <HireCTASection />
        </SummaryContent>
      </Box>

      <Box display="flex" flexDirection="column" gap={6} mt={6}>
        <FunnelChart
          current={funnelData}
          history={history ?? []}
          tooltip={formatMessage('hire_stats_funnel_chart_title_tooltip')}
          title={formatMessage('hire_stats_funnel_chart_title')}
        />
        <HireBestLeastPerformingJobs />
        <HireJobsPerformanceStatistics />
      </Box>
    </div>
  )
}

function HireStats() {
  useChartJsPluginsRegistration()

  return (
    <>
      <Box my={3}>
        <Typography variant="subtitle1">
          <FormattedMessage id="statistics_subtitle_hire" />
        </Typography>
      </Box>
      <HireStatsContextProvider>
        <Filters />
        <_HireStats />
      </HireStatsContextProvider>
    </>
  )
}

export default HireStats
