import { useContext } from 'react'
import { useQuery, UseQueryResult, QueryKey } from '@tanstack/react-query'
import { useCurrentCompany } from '@hooks/useCompanies'
import useDebouncedMemo from '@hooks/useDebouncedMemo'
import { PaginatedResponse } from '../../../../actions/api/services/genericTypes'
import { ReachStatsService } from '../../../../actions/api/services/reachStatsService'
import {
  BestWorstPerformingJobs,
  JobPerformanceStats,
} from '../../../../components/Statistics/BestLeastPerformingJobs/types'
import {
  CampaignStatistics,
  CampaignStatisticsCpa,
  FunnelChartHistoryItem,
  ReachJobStatistics,
  ReachStatsFilters,
} from '../../../../types'
import { JobsPerformanceContext } from '../contexts/JobsPerformanceContext'
import { ReachStatsContext } from '../contexts/ReachStatsContext'

export const ReachStatsQueryKeys = {
  campaignStatistics: 'CAMPAIGN_STATISTICS',
  campaignStatisticsHistory: 'CAMPAIGN_STATISTICS_HISTORY',
  campaignStatisticsCpa: 'CAMPAIGN_STATISTICS_CPA',
  jobsStatistics: 'JOBS_STATISTICS',
  jobPerformanceStats: 'JOB_PERFORMANCE_STATS',
}

function useReachStatsBase<T>(
  key: string,
  queryFn: (companyId: string, filters: ReachStatsFilters) => Promise<T>,
  identifierKey: string,
  ...queryKeys: unknown[]
) {
  const company = useCurrentCompany()
  const companyId = company?.id
  const { filters } = useContext(ReachStatsContext)

  const query = useQuery<T>({
    queryKey: [identifierKey, companyId, filters.marketing_contract_group_id, filters.from, filters.to, ...queryKeys],
    queryFn: () => queryFn(companyId, filters),
    enabled: !!companyId && !!filters.marketing_contract_group_id,
  })

  return { ...query, [key]: query.data } as UseQueryResult<T, Error> & { [key: string]: T }
}

export const useCampaignStats = () =>
  useReachStatsBase<CampaignStatistics>(
    'stats',
    ReachStatsService.campaignStatistics,
    ReachStatsQueryKeys.campaignStatistics
  )

export const useCampaignStatsHistory = () =>
  useReachStatsBase<FunnelChartHistoryItem[]>(
    'history',
    ReachStatsService.campaignStatisticsHistory,
    ReachStatsQueryKeys.campaignStatisticsHistory
  )

export const useCampaignStatsCpa = () =>
  useReachStatsBase<CampaignStatisticsCpa>(
    'cpaStats',
    ReachStatsService.campaignStatisticsCpa,
    ReachStatsQueryKeys.campaignStatisticsCpa
  )

export const useJobPerformanceStats = (criteria: string) =>
  useReachStatsBase<BestWorstPerformingJobs<JobPerformanceStats>>(
    'jobPerformanceStats',
    (companyId, filters) => ReachStatsService.bestWorstPerformingJobs(companyId, filters, criteria),
    ReachStatsQueryKeys.jobPerformanceStats,
    criteria
  )

type UseJobsStatistics = {
  jobsStats: ReachJobStatistics[]
  itemsPerPage: number
  totalItems: number
  totalPages: number
} & Omit<UseQueryResult<PaginatedResponse<ReachJobStatistics[]>, Error>, 'data'>

export function useJobsStatistics(): UseJobsStatistics {
  const company = useCurrentCompany()
  const companyId = company?.id
  const { filters } = useContext(ReachStatsContext)
  const { pagination, querySort } = useContext(JobsPerformanceContext)

  // Pagination & sorting are reset when filters change
  // So, debounce query parameters to avoid unnecessary requests
  const queryParameters: QueryKey = useDebouncedMemo<QueryKey>(
    () => [
      filters.marketing_contract_group_id,
      filters.from,
      filters.to,
      pagination.pageIndex,
      pagination.pageSize,
      querySort?.key,
      querySort?.direction,
    ],
    [filters, pagination, querySort],
    300
  )

  const { data, ...rest } = useQuery<PaginatedResponse<ReachJobStatistics[]>>({
    queryKey: [ReachStatsQueryKeys.jobsStatistics, companyId, ...queryParameters],
    queryFn: () =>
      ReachStatsService.jobsStatistics(companyId, {
        ...filters,
        page: pagination.pageIndex,
        items_per_page: pagination.pageSize,
        sort: querySort,
      }),
    enabled: !!companyId && !!filters.marketing_contract_group_id,
  })

  return {
    jobsStats: data?.data ?? [],
    itemsPerPage: data?.items_per_page ?? 0,
    totalItems: data?.total_items ?? 0,
    totalPages: data?.total_pages ?? 0,
    ...rest,
  }
}
