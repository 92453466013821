import React, { useCallback, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import { FieldArray } from 'formik'
import { useFormatMessage } from '@hooks/util'
import { MAX_SCREENING_QUESTION_CATALOG_QUESTIONS, SCREENING_QUESTION_WARNING_AMOUNT } from '../../../../constants'
import FieldInfoMessage from '../../../../jobz_ui_components/FieldInfoMessage'
import { Job } from '../../../../types'
import AddQuestion from '../../AddQuestion'
import catalogQuestionToScreeningQuestion from '../../catalogQuestionToScreeningQuestion'
import CollapsableSection from '../../CollapsableCatalogSection'
import createCustomQuestion from '../../createCustomQuestion'
import NoQuestionsSelected from '../../NoQuestionsSelected'
import ScreeningQuestionItem from '../../ScreeningQuestion/ScreeningQuestionItem'
import ScreeningQuestionSticky from '../../ScreeningQuestion/ScreeningQuestionSticky'
import SelectedQuestionsList from '../../SelectedQuestionsList'
import useScreeningQuestionsFormState from '../useScreeningQuestionsFormState'
import { groupByStickiness } from '../utils'

type Props = {
  job: Job
  setCollapsed(collapsed: Record<string, boolean>): void
  collapsed: Record<string, boolean>
  hasStickyQuestions: boolean
  hasNoImportedScreeningQuestions: boolean
}

const SQFormScreeningQuestionsListSection = ({
  job,
  setCollapsed,
  collapsed,
  hasStickyQuestions,
  hasNoImportedScreeningQuestions,
}: Props) => {
  const formatMessage = useFormatMessage()
  const { localeVariant, catalogQuestions, screeningQuestions } = useScreeningQuestionsFormState(job)
  const { stickyQuestions, customQuestions } = useMemo(() => groupByStickiness(screeningQuestions), [
    screeningQuestions,
  ])

  const maxQuestionsAdded = screeningQuestions.length >= MAX_SCREENING_QUESTION_CATALOG_QUESTIONS
  const showTooManyQuestionsWarning =
    !maxQuestionsAdded && screeningQuestions.length >= SCREENING_QUESTION_WARNING_AMOUNT
  const selectedCatalogQuestionsIdMap = screeningQuestions
    .filter((question) => question.catalog_question_id)
    .reduce((acc, question) => ({ ...acc, [question.catalog_question_id as number]: true }), {})

  const setCollapsedState = useCallback(
    (key: string, state: boolean) => {
      setCollapsed({ ...collapsed, [key]: state })
    },
    [collapsed, setCollapsed]
  )

  return (
    <FieldArray name="screeningQuestions" validateOnChange={false}>
      {({ push, move, remove }) => (
        <>
          {hasStickyQuestions && (
            <Box mb={2}>
              <SelectedQuestionsList
                droppableId="screeningQuestionsSticky"
                screeningQuestions={stickyQuestions}
                maxQuestionsAdded={maxQuestionsAdded}
                remove={remove}
                move={move}
                collapsed={collapsed}
                collapse={setCollapsedState}
                ScreeningQuestionComponent={ScreeningQuestionSticky}
                dragAndDroppable
              />
            </Box>
          )}

          <Box mb={2}>
            {customQuestions.length ? (
              <>
                <SelectedQuestionsList
                  droppableId="screeningQuestionsCustom"
                  collapsed={collapsed}
                  screeningQuestions={customQuestions}
                  maxQuestionsAdded={maxQuestionsAdded}
                  remove={remove}
                  move={move}
                  collapse={setCollapsedState}
                  ScreeningQuestionComponent={ScreeningQuestionItem}
                  dragAndDroppable
                  customQuestionsStartIndex={stickyQuestions.length}
                />
                {showTooManyQuestionsWarning && (
                  <Alert severity="warning" sx={{ mt: 1 }}>
                    <FormattedMessage id="employer_sq_too_many_questions_warning" />
                  </Alert>
                )}
                {maxQuestionsAdded && (
                  <FieldInfoMessage
                    sx={{ mt: 1 }}
                    text={formatMessage('employer_job_form_max_screening_questions_reached', {
                      max: MAX_SCREENING_QUESTION_CATALOG_QUESTIONS,
                    })}
                  />
                )}
              </>
            ) : (
              <NoQuestionsSelected />
            )}
          </Box>

          {hasNoImportedScreeningQuestions && (
            <>
              <Box mt={2}>
                <CollapsableSection title={<Typography variant="subtitle3">Create custom question</Typography>}>
                  <AddQuestion
                    question={createCustomQuestion(job.locale)}
                    disabled={maxQuestionsAdded}
                    push={push}
                    setCollapsed={setCollapsed}
                  />
                </CollapsableSection>
              </Box>

              <Divider />

              <Box mt={2}>
                <CollapsableSection
                  title={
                    <Typography variant="subtitle3">
                      <FormattedMessage id="employer_sq_catalog_qualifications_section_title" />
                    </Typography>
                  }
                >
                  {catalogQuestions?.qualifications
                    .filter((question) => !selectedCatalogQuestionsIdMap.hasOwnProperty(question.id))
                    .map((catalogQuestion) => (
                      <React.Fragment key={catalogQuestion.id}>
                        <AddQuestion
                          question={catalogQuestionToScreeningQuestion({
                            catalogQuestion,
                            locale: job.locale,
                            localeVariant,
                          })}
                          disabled={maxQuestionsAdded}
                          push={push}
                          setCollapsed={setCollapsed}
                        />
                      </React.Fragment>
                    ))}
                </CollapsableSection>
              </Box>

              <Divider />

              <Box mt={2}>
                <CollapsableSection
                  title={
                    <Typography variant="subtitle3">
                      <FormattedMessage id="employer_sq_catalog_availability_section_title" />
                    </Typography>
                  }
                >
                  {catalogQuestions?.availability
                    .filter((question) => !selectedCatalogQuestionsIdMap.hasOwnProperty(question.id))
                    .map((catalogQuestion) => (
                      <React.Fragment key={catalogQuestion.id}>
                        <AddQuestion
                          question={catalogQuestionToScreeningQuestion({
                            catalogQuestion,
                            locale: job.locale,
                            localeVariant,
                          })}
                          disabled={maxQuestionsAdded}
                          push={push}
                          setCollapsed={setCollapsed}
                        />
                      </React.Fragment>
                    ))}
                </CollapsableSection>
              </Box>
            </>
          )}
        </>
      )}
    </FieldArray>
  )
}

export default SQFormScreeningQuestionsListSection
