import React, { useContext } from 'react'
import { useHireJobsStatistics } from '@hooks/statistics/useHireStats'
import { HireStatsService } from '../../../../actions/api/services/hireStatsService'
import StatisticsDataDownloadButton from '../../../../components/Statistics/common/StatisticsDataDownloadButton'
import { HireJobsPerformanceContext } from '../contexts/HireJobsPerformanceContext'
import { useHireStatsFilters } from '../contexts/HireStatsContext'
import { HireStatsFilters } from '../types'

interface CommonProps {
  variant?: 'outlined' | 'text'
}

export default function HireDataDownloadButton({ variant = 'outlined' }: CommonProps) {
  const { filters } = useHireStatsFilters()
  const { querySort } = useContext(HireJobsPerformanceContext)
  const { isLoading } = useHireJobsStatistics()

  if (isLoading) {
    return null
  }

  return (
    <StatisticsDataDownloadButton<HireStatsFilters>
      variant={variant}
      exportFn={HireStatsService.exportJobsOverview}
      filters={filters}
      fileNamePrefix="HeyJobs_Hire_Statistics"
      querySort={querySort}
    />
  )
}
