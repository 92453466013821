// Our input components integrated with redux-form
// Drop these into a redux form, and they will just work
// Only this integration and basic layout belongs here -
// to change styles, modify the underlying components
import React from 'react'
import { useIntl } from 'react-intl'
import EventOutlined from '@mui/icons-material/EventOutlined'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import styled from 'styled-components'
import { DATE_FORMAT_NUMERICAL_INTL } from '../dateTimeFormatter'
import BenefitIcon from '../jobz_ui_components/BenefitIcon'
import { DatePicker } from '../jobz_ui_components/date-picker'
import ErrorMessage from '../jobz_ui_components/ErrorMessage'
import { FormControl } from '../jobz_ui_components/material-ui'
import { decode, encode } from '../utils/dateCoder'
import { LabelContent } from './FormFieldLabel'
import ImageUploaderLocalPreview from './ImageUploaderLocalPreview'
import { InfoIconWithTooltip } from './InfoIconWithTooltip'
import Subtitle3 from './shared/Subtitle3'
import sharedStyles from './sharedStyles'

export type FormTextFieldProps = Omit<TextFieldProps, 'error'> & {
  formControlStyle?: Record<string, any>
  floatingLabelStyle?: Record<string, any>
  floatingLabelText?: string
  hintStyle?: {
    top: string
  }
  size?: string
  error?: string
}

export const FormTextField = ({
  error,
  floatingLabelText,
  floatingLabelStyle,
  formControlStyle,
  className,
  required,
  ...props
}: FormTextFieldProps) => {
  return (
    <FormControl id={`${props.id}_div`} style={formControlStyle} fullWidth={props.fullWidth}>
      {floatingLabelText && (
        <LabelContent
          style={{ ...sharedStyles.labelStyle, ...floatingLabelStyle }}
          text={floatingLabelText}
          required={required}
          disabled={props.disabled}
          className={className}
          htmlFor={props.id}
        />
      )}
      <TextField id={props.id} {...props} />
      {error && <ErrorMessage text={error} />}
    </FormControl>
  )
}

const getItemByValue = (menuItems) => (value) => menuItems?.find((item) => item.value === value)

export type MenuItemProps = {
  reactKey: string | number
  value: string | number
  primaryText: string
  secondaryText?: string
  icon?: React.ReactNode
}

type FormSelectFieldProps = {
  id: string
  name?: string
  formControlStyle?: Record<string, any>
  floatingLabelStyle?: Record<string, any>
  fullWidth?: boolean
  floatingLabelText?: React.ReactNode
  className?: string
  required?: boolean
  disabled?: boolean
  style?: Record<string, any>
  type?: string
  value?: string
  hintText?: React.ReactNode
  onChange?: (...args: Array<any>) => any
  onBlur?: (...args: Array<any>) => any
  error?: string
  touched?: boolean
  multiple?: boolean
  autoWidth?: boolean
  field?: Record<string, any>
  form?: Record<string, any>
  MenuProps?: Record<string, any>
  menuItems: MenuItemProps[]
  variant?: 'filled' | 'outlined' | 'standard'
  meta?: any
  labelId?: string
  inputProps?: any
  IconComponent?: React.ReactNode
  renderMenuItem?: (menuItem: MenuItemProps) => React.ReactNode
}

export const FormSelectField = ({
  name,
  value,
  onChange,
  onBlur,
  touched,
  error,
  renderMenuItem,
  ...props
}: FormSelectFieldProps) => {
  const intl = useIntl()
  const field = props.field || {
    name,
    value,
    onChange,
    onBlur,
  }
  const errorMessageId = (props.form?.touched[field.name] && props.form?.errors[field.name]) || (touched && error)
  return (
    <FormControl
      id={`${props.id}-container`}
      fullWidth={props.fullWidth}
      variant={props.variant}
      style={props.formControlStyle}
    >
      <TextField
        error={!!errorMessageId}
        select
        SelectProps={{
          multiple: props.multiple,
          renderValue: (value) => getItemByValue(props.menuItems)(value)?.primaryText || props.hintText,
          style: { ...props.style, maxHeight: 40 },
          // @ts-expect-error - TS2322 - Type 'string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | ReactFragment | OverridableComponent<SvgIconTypeMap<{}, "svg">>' is not assignable to type 'ElementType<any> | undefined'.
          IconComponent: props.IconComponent ?? KeyboardArrowDown,
          MenuProps: props.MenuProps,
          displayEmpty: !!props.menuItems?.length,
          fullWidth: props.fullWidth,
          ...field,
        }}
        id={props.id}
        variant="outlined"
        disabled={props.disabled}
        fullWidth={props.fullWidth}
        label={props.floatingLabelText}
        InputLabelProps={{
          shrink: true,
        }}
        size="small"
        helperText={errorMessageId && intl.formatMessage({ id: errorMessageId }, { name: field.name })}
      >
        {props.menuItems?.map((menuItem) =>
          renderMenuItem ? (
            renderMenuItem(menuItem)
          ) : (
            <MenuItem
              data-testid={`${props.id}--${menuItem.value}`}
              key={menuItem.reactKey}
              value={menuItem.value}
              className="menuItem"
            >
              {menuItem.primaryText}
            </MenuItem>
          )
        )}
      </TextField>
    </FormControl>
  )
}

const formatDate = (country) => DATE_FORMAT_NUMERICAL_INTL(country)

type DatePickerProps = {
  id: string
  fullWidth?: boolean
  floatingLabelText: string
  required?: boolean
  disabled?: boolean
  className?: string
  hintText: string
  value: (string | null | undefined) | (number | null | undefined)
  defaultValue?: string | number | null
  onChange: (...args: Array<any>) => any
  minDate?: Date
  maxDate?: Date
  autoWidth?: boolean
  country: string
  style?: Record<string, any>
  error?: string
  showErrors?: boolean
  clearLabel?: string
  clearable?: boolean
  inputVariant?: string
  margin?: string
  name?: string
  touched?: boolean
  meta?: any
  cancelLabel?: string
  InputLabelProps?: Record<string, any>
}

const StyledTooltip = styled(Tooltip)`
  .${tooltipClasses.tooltip} {
    font-size: 14px !important;
  }
`

export const FormDatePicker = (props: DatePickerProps) => {
  const intl = useIntl()
  return (
    <FormControl id={`${props.id}-container`} fullWidth={props.fullWidth}>
      <DatePicker
        autoOk
        id={props.id}
        placeholder={props.hintText}
        value={decode(props.value)}
        onChange={(value) => {
          props.onChange(value ? encode(value) : '')
        }}
        minDate={decode(props.minDate)}
        maxDate={decode(props.maxDate)}
        fullWidth={!props.autoWidth}
        disabled={props.disabled}
        format={formatDate(intl.locale.toUpperCase())}
        style={props.style}
        margin={props.margin}
        inputVariant={props.inputVariant}
        clearable={props.clearable}
        label={props.floatingLabelText}
        cancelLabel={props.cancelLabel}
        clearLabel={props.clearLabel}
        keyboardIcon={<EventOutlined fontSize="small" />}
        KeyboardButtonProps={{ style: { paddingRight: 0 } }}
        defaultValue={decode(props.defaultValue)}
      />

      {(props.touched || props.showErrors) && props.error && (
        <ErrorMessage id={`${props.id}-error`} text={intl.formatMessage({ id: props.error }, { name: props.name })} />
      )}
    </FormControl>
  )
}

type FormCheckboxProps = {
  id: string
  name?: string
  disabled?: boolean
  style?: Record<string, any>
  value?: string
  onChange?: (...args: Array<any>) => any
  onBlur?: (...args: Array<any>) => any
  error?: string
  touched?: boolean
  field?: Record<string, any>
  form?: Record<string, any>
  label?: React.ReactNode
  labelPlacementOnRight?: boolean
  showErrors?: boolean
  icon?: React.ReactNode
  checkedIcon?: React.ReactNode
}
export const FormCheckbox = ({
  name,
  value,
  onChange,
  onBlur,
  touched,
  error,
  icon,
  checkedIcon,
  label,
  ...props
}: FormCheckboxProps) => {
  const intl = useIntl()
  const field = props.field || {
    name,
    value,
    onChange,
    onBlur,
    label,
  }
  const isTouched = props.form?.touched[field.name] || touched
  const errorMessageId = (props.form?.touched[field.name] && props.form?.errors[field.name]) || (touched && error)
  const CheckboxControl = (
    <StyledTooltip
      style={{
        fontSize: '14px !important',
      }}
      disableHoverListener={!props.labelPlacementOnRight}
      title={intl.formatMessage({ id: 'employer_job_form_screening_question_yes_no_preferred_answer_label' })}
    >
      <Checkbox
        id={props.id}
        icon={icon}
        checkedIcon={checkedIcon}
        checked={Boolean(field.value)}
        style={props.style}
        disabled={props.disabled}
        color="primary"
        {...field}
      />
    </StyledTooltip>
  )
  return (
    <>
      {label ? (
        <FormControlLabel
          disabled={props.disabled}
          control={CheckboxControl}
          style={
            props.labelPlacementOnRight
              ? {
                  width: '100%',
                  justifyContent: 'space-between',
                  marginLeft: '0px',
                }
              : {}
          }
          labelPlacement={props.labelPlacementOnRight ? 'start' : 'end'}
          label={label}
        />
      ) : (
        CheckboxControl
      )}
      {(isTouched || props.showErrors) && errorMessageId && (
        <ErrorMessage
          id={`${props.id}-error`}
          text={intl.formatMessage({ id: errorMessageId }, { name: field.name })}
        />
      )}
    </>
  )
}
export type FormCheckboxSelectionElement = {
  key: string
  label: string
}
type FormCheckboxSelectionProps = {
  label?: string
  required?: boolean
  possible: FormCheckboxSelectionElement[]
  value: string[]
  onChange: (newValue: string[]) => void
  disabled?: boolean
}

const StyledFormControlLabel = styled(FormControlLabel)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const FormCheckboxSelection = (props: FormCheckboxSelectionProps) => {
  const possible = props.possible || []
  return (
    <>
      {props.label && <Subtitle3 gutterBottom>{props.label}</Subtitle3>}
      <Grid container>
        {possible.map((p: FormCheckboxSelectionElement) => {
          return (
            <Grid key={p.key} item xs={12} sm={4}>
              <StyledFormControlLabel
                label={
                  <Box display="flex" alignItems="center">
                    <BenefitIcon codename={p.key} disabled={props.disabled} />
                    {p.label}
                  </Box>
                }
                control={
                  <Checkbox
                    color="primary"
                    disabled={props.disabled}
                    onChange={(_, state) => {
                      if (state) {
                        props.onChange([...props.value, p.key])
                      } else {
                        props.onChange(props.value.filter((v) => v !== p.key))
                      }
                    }}
                    checked={props.value?.includes(p.key)}
                  />
                }
              />
            </Grid>
          )
        })}
      </Grid>
    </>
  )
}
export const FormImageUploaderLocalPreview = (props: Record<string, any>) => {
  return (
    <div id="imageUploaderDiv">
      {!props.noHeading && (
        <Box display="flex" alignItems="center" mb={2}>
          <Subtitle3>{props.label}</Subtitle3>
          <InfoIconWithTooltip tooltip={props.infoMessage} />
        </Box>
      )}

      <ImageUploaderLocalPreview
        id={props.id}
        className="imageUploader"
        onChange={(event) => {
          const value = event.target.files[0]
          props.input.onChange(value)
        }}
        onRemove={() => props.input.onChange(null)}
        onBlur={(event) => {
          props.input.onBlur(event.target.files[0])
        }}
        removeImageKey={props.removeImageKey}
        initialImageSrc={props.initialImageSrc}
        noInitialImageMessageKey={props.noInitialImageMessageKey}
        initialImageSelectedMessageKey={props.initialImageSelectedMessageKey}
        localImageSelectedMessageKey={props.localImageSelectedMessageKey}
      />
    </div>
  )
}
